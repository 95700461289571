<template>
  <v-row>
    <v-col cols="12" class="col-sm-2">
      <div v-if="authorizedLink && !branch.length" class="pa-4 text-center">
        Accurate unauthorized!
        <div class="pt-4">
          <v-btn :href="authorizedLink" target="_blank" depressed color="orange darken-1 white--text rounded-0 font-weight-bold" x-large block>
            Authorize
          </v-btn>
        </div>
      </div>
      <v-card v-if="branch.length" outlined class="pa-4 c-pointer d-flex align-center fill-width justify-space-between" @click.prevent="branchId = 0">
        Semua Cabang
        <v-icon v-if="!branchId">
          mdi-chevron-right
        </v-icon>
      </v-card>
      <v-card v-for="(b, iB) in branch" :key="'branch-' + iB" outlined class="pa-4 c-pointer d-flex align-center fill-width justify-space-between" @click.prevent="branchId = parseInt(b.id)">
        {{ b.name }}
        <v-icon v-if="branchId === parseInt(b.id)">
          mdi-chevron-right
        </v-icon>
      </v-card>
    </v-col>
    <v-col v-if="!(authorizedLink && !branch.length)" cols="12" class="col-sm-10">
      <v-data-table
        :headers="headers"
        :items="table"
        item-key="id"
        :server-items-length="tableTotal"
        :page.sync="tablePage"
        :items-per-page.sync="tableLimit"
        :options.sync="options"
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100]
        }"
        :loading="tableLoading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.number`]="{ item }">
          <div class="py-2">
            {{ item.number }}
          </div>
        </template>
        <template v-slot:[`item.customer`]="{ item }">
          <div class="py-2">
            {{ item.customer.name || '-' }}
          </div>
        </template>
        <template v-slot:[`item.statusName`]="{ item }">
          <div class="py-2">
            {{ item.statusName || '-' }}
          </div>
        </template>
        <template v-slot:[`item.totalAmount`]="{ item }">
          <div class="py-2">
            {{ $price(item.totalAmount) }}
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
let reqTimeout = null
export default {
  props: {
    authorized: {
      type: Boolean,
      default: false
    },
    authorizedLink: {
      type: String,
      default: ''
    },
    branch: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    branchId: 0,
    dates: [],
    tableLoading: false,
    headers: [
      { text: 'Nomor', value: 'number', sortable: false },
      // { text: 'Tanggal', value: 'shipping_total', sortable: false },
      { text: 'Pelanggan', value: 'customer', sortable: false },
      { text: 'Status', value: 'statusName', sortable: false },
      { text: 'Total', value: 'totalAmount', sortable: false }
    ],
    tableSearch: '',
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 20,
    tableSearchStatus: null,
    options: {
      sortBy: ['created'],
      sortDesc: 'DESC',
      itemsPerPage: 20,
      page: 1
    }
  }),
  computed: {
  },
  watch: {
    options () {
      if (reqTimeout) {
        clearTimeout(reqTimeout)
      }
      reqTimeout = setTimeout(() => {
        this.ACCURATE_SALES_GET()
      }, 300)
    },
    branchId: {
      immediate: true,
      handler (v) {
        this.tablePage = 1
        if (reqTimeout) {
          clearTimeout(reqTimeout)
        }
        reqTimeout = setTimeout(() => {
          this.ACCURATE_SALES_GET()
        }, 300)
      }
    },
    $route: {
      immediate: true,
      handler (to, from) {
        if (to) {
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    ACCURATE_SALES_GET () {
      this.tableLoading = true

      /* eslint-disable-next-line */
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      // query += (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')

      const q = `?page=${page}&pageSize=${itemsPerPage}&sort=&branchId=${this.branchId}`
      this.$store.dispatch('logistic/ACCURATE_SALES_GET', q)
        .then((res) => {
          this.tableLoading = false
          if (res && res.status && res.data.data.response) {
            this.table = res.data.data.data.d || []
            this.tableTotal = res.data.data.data.sp.rowCount
          }
        })
    }
  }
}
</script>
